import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import StickyBox from 'react-sticky-box';
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttButton, SttHidden, SttTranslateHook } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from '../anexos/imagens';
import Invalidar from '../exame/invalidar';
import { useSignals } from '@preact/signals-react/runtime';
import { useSignal } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import laudar from '../../signals/laudo';

const useStyles = makeStyles((theme) => ({
	wrapperBotao: {
		display: 'flex',
		justifyContent: 'flex-end'
	}
}));

const Container = (props) => {
	const { exame, idRede, callbackProximoExame, notificar } = props;

	const { strings } = useContext(SttTranslateHook.I18nContext);
	const location = useLocation();
	const classes = useStyles();
	const ESPIRO_API_BASE_URL = global.gConfig.url_base_espiro;

	const contextoFabricalaudo = useSignal(location.pathname === '/laudo');
	const invalidar = useSignal(false);

	useSignals();

	const handleCloseAlerta = () => {
		alerta.value = { ...alerta.value, open: false };
		if (contextoFabricalaudo.value) {
			callbackProximoExame();
		} else {
			laudar.value = false;
		}
	};

	const voltar = () => {
		// Controle de componentes. Volta para tela anterior
		laudar.value = false;

		// Cancela emissão do laudo
		cancelarEmissao();
	};

	const cancelarEmissao = () => {
		axios
			.post(`${ESPIRO_API_BASE_URL}/laudo/cancelar`, { idExame: exame.value.exame.id_exame }, { headers: getHeaders() })
			.catch((err) => console.log(err));
	};

	const ignorarExame = () => {
		notificar(strings.ignorandoExame);
		// Insere exame ignorado
		axios
			.post(`${ESPIRO_API_BASE_URL}/laudo/ignorar-exame`, { idExame: exame.value.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
			.then((resposta) => {
				// Cancela emissão do laudo
				cancelarEmissao();
				// Carrega o próximo exame
				callbackProximoExame();
			})
			.catch((err) => {
				console.log(err);
				alerta.value = {
					title: strings.erro,
					message: strings.erroIgnorarExame,
					type: 'error',
					open: true,
					options: [
						{
							title: strings.ok,
							onClick: () => {
								alerta.value = {
									...alerta.value,
									open: false
								};
								handleCloseAlerta();
							}
						}
					],
					onClose: () => {
						alerta.value = {
							...alerta.value,
							open: false
						};
						handleCloseAlerta();
					}
				};
			});
	};

	return (
		<>
			<SttGrid
				container
				spacing={3}
			>
				<SttGrid
					item
					xs={12}
					className={classes.wrapperBotao}
				>
					{
						<SttButton
							variant="contained"
							danger={+true}
							onClick={() => (invalidar.value = true)}
						>
							{strings.invalidar}
						</SttButton>
					}
					{!contextoFabricalaudo.value && (
						<SttButton
							type="button"
							variant="outlined"
							color="primary"
							onClick={voltar}
						>
							{strings.voltar}
						</SttButton>
					)}

					{contextoFabricalaudo.value && (
						<SttButton
							type="button"
							variant="outlined"
							color="primary"
							onClick={ignorarExame}
						>
							{strings.proximoExame}
						</SttButton>
					)}
				</SttGrid>
			</SttGrid>
			<SttGrid
				container
				spacing={3}
			>
				<SttHidden mdUp>
					<SttGrid
						item
						xs={12}
					>
						<Imagens idExame={exame.value.exame.id_exame} />
					</SttGrid>
					<SttGrid
						item
						xs={12}
					>
						<Laudo
							dados={exame}
							callbackProximoExame={callbackProximoExame}
						/>
					</SttGrid>
				</SttHidden>
				<SttHidden only={['xs', 'sm']}>
					<SttGrid
						item
						xs={6}
					>
						<StickyBox>
							<Imagens idExame={exame.value.exame.id_exame} />
						</StickyBox>
					</SttGrid>
					<SttGrid
						item
						xs={6}
					>
						<Laudo
							dados={exame}
							callbackProximoExame={callbackProximoExame}
						/>
					</SttGrid>
				</SttHidden>
			</SttGrid>
			{invalidar.value && (
				<Invalidar
					invalidar={invalidar}
					setInvalidar={(i) => (invalidar.value = i)}
					idExame={exame.value.exame.id_exame}
					idRede={idRede}
					callback={callbackProximoExame}
				/>
			)}
		</>
	);
};

Container.propTypes = {
	exame: PropTypes.object.isRequired,
	idRede: PropTypes.number,
	callbackProximoExame: PropTypes.func,
	callbackExameCarregado: PropTypes.func
};

export default Container;
