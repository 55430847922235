import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttGrid,
    SttTranslateHook,
    SttDivider
} from '@stt-componentes/core';
import { TABAGISMO } from '../../common/Constants';
import Form from './form';
import { SttIMC } from '@stt-componentes/paciente'
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const Laudo = ({ dados, callbackProximoExame }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const exame = dados.value;

    const comorbidades = exame.indicacao.comorbidades.map(c => c.descricao);

    return (
        <SttGrid container spacing={2}>
            <SttGrid item xs={12}>
                <SttHeading variant="h3" color="primary" align="center" className={classes.titulo}>
                    <span>{`${exame.exame.descricao_exame} - ${exame.exame.data_exame}`}</span>
                    <span>{`${exame.exame.nome_instituicao} - ${exame.exame.nome_cidade}/${exame.exame.sigla_uf}`}</span>
                </SttHeading>

                <SttExpansionPanel
                    title={strings.paciente}
                    compact
                    children={
                        <div>
                            <SttTextItem key="1" title={strings.nome} content={exame.exame.nome_paciente} />
                            <SttTextItem key="3" title={strings.sexo} content={exame.exame.sexo_paciente} />
                            {exame.exame.cartao_sus_paciente && <SttTextItem key="7" title={strings.cns} content={exame.exame.cartao_sus_paciente} />}
                            <SttTextItem key="2" title={strings.dataNascimento} content={exame.exame.data_nascimento_paciente_formatada} />
                            {exame.indicacao && <SttTextItem key="4" title={strings.peso} content={`${exame.indicacao.peso_paciente} kg`} />}
                            {exame.indicacao && <SttTextItem key="5" title={strings.altura} content={`${exame.indicacao.altura_paciente} cm`} />}
                            {
                                exame.indicacao?.peso_paciente && exame.indicacao?.altura_paciente && exame.exame.data_nascimento &&
                                <SttIMC values={{
                                    peso: exame.indicacao.peso_paciente,
                                    altura: exame.indicacao.altura_paciente,
                                    dataNascimento: exame.exame.data_nascimento,
                                }} strings={strings} />
                            }
                        </div>
                    }
                />

                <SttDivider className={classes.divider} />

                {
                    (exame.exame.observacao_tecnica || exame.indicacao) &&
                    <SttExpansionPanel
                        title={strings.infoClinicas}
                        compact
                        children={
                            <div>
                                {exame.exame.observacao_tecnica && <SttTextItem key="1" title={strings.observacaoTecnica} content={exame.exame.observacao_tecnica} />}
                                {exame.indicacao.descricacao_tabagismo && <SttTextItem key="2" title={strings.tabagismo} content={exame.indicacao.descricacao_tabagismo} />}
                                {
                                    (exame.indicacao.tabagismo === TABAGISMO.FUMANTE || exame.indicacao.tabagismo === TABAGISMO.EX_FUMANTE) &&
                                    <>
                                        <SttTextItem key="2.1" title={strings.cigarrosDia} content={exame.indicacao.cigarros_dia} />
                                        <SttTextItem key="2.2" title={strings.anosFuma} content={exame.indicacao.anos_tabagismo} />
                                    </>
                                }
                                {exame.indicacao.tabagismo === TABAGISMO.EX_FUMANTE && <SttTextItem key="2.3" title={strings.anosParouFumo} content={exame.indicacao.anos_parou_fumar} />}

                                <SttTextItem key="3" title={strings.etilismo} content={exame.indicacao.etilismo} />

                                {comorbidades.length > 0 && <SttTextItem key="4" title={strings.comorbidades} content={comorbidades.join(', ')} />}
                                {exame.indicacao.motivo_exame && <SttTextItem key="5" title={strings.motivoExame} content={exame.indicacao.motivo_exame} />}
                                {exame.indicacao.resumo_historia_clinica && <SttTextItem key="6" title={strings.observacoes} content={exame.indicacao.resumo_historia_clinica} />}
                                {exame.indicacao.hmf && <SttTextItem key="7" title={strings.hmf} content={exame.indicacao.hmf} />}
                                {exame.indicacao.hmp && <SttTextItem key="8" title={strings.hmp} content={exame.indicacao.hmp} />}
                                {exame.indicacao.medicamentos_em_uso && <SttTextItem key="9" title={strings.medicamentosUso} content={exame.indicacao.medicamentos_em_uso} />}
                            </div>
                        }
                    />
                }

                <SttDivider className={classes.divider} />

                <Form
                    idExame={exame.exame.id_exame}
                    idLaudoEmissao={exame.idLaudoEmissao}
                    idPaciente={exame.exame.id_paciente}
                    idSolicitante={exame.exame.id_medico_solicitante}
                    nomeSolicitante={exame.exame.nome_medico_solicitante}
                    callbackProximoExame={callbackProximoExame}
                />
            </SttGrid>
        </SttGrid>
    )

}

Laudo.propTypes = {
    dados: PropTypes.object.isRequired,
    callbackProximoExame: PropTypes.func
};

export default Laudo;