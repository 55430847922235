import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { getHeaders } from '../../request';
import axios from 'axios';
import PropTypes from 'prop-types';
import { MODALIDADE } from '../../common/Constants';
import {
    SttLoading,
    SttGrid,
    SttButton,
    SttModal,
    SttAutocomplete,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { batch } from '@preact/signals-react';
import alerta from '../../signals/alerta';
import { listar } from '../../signals/exame';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    modalBody: {
        overflow: 'hidden'
    }
}));

const Priorizar = ({ priorizar, setPriorizar, idExame, idInstituicao, callback }) => {
    const classes = useStyles();
    useSignals();

    const { strings } = useContext(SttTranslateHook.I18nContext);

    const motivosPriorizacao = useSignal([]);
    const motivo = useSignal(null);
    const priorizando = useSignal(false);

    useSignalEffect(() => {
        // Carrega a lista de motivos de priorização de exames
        if (!motivosPriorizacao.length) {
            axios.get(`${global.gConfig.url_base_exames}/motivo-priorizacao`, { params: { siglaModalidade: MODALIDADE.SIGLA }, headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        motivosPriorizacao.value = response.data;
                    }
                })
                .catch(err => console.log(err));
        }
    });

    const fecharPriorizar = () => {
        motivo.value = null;
        setPriorizar(false);
    }

    const selecionarMotivo = (event, novoMotivo) => {
        motivo.value = novoMotivo;
    }

    const priorizarExame = () => {
        priorizando.value = true;

        const dados = {
            exame: idExame,
            motivo: motivo.value.id,
            instituicao: idInstituicao,
            modalidade: MODALIDADE.DESCRICAO_PT
        }
        axios.post(`${global.gConfig.url_base_exames}/priorizar`, dados, { headers: getHeaders() })
            .then((resposta) => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.sucesso,
                    type: 'success',
                    message: strings.examePriorizado,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            if (callback) {
                                callback();
                            }
                            fecharPriorizar();
                            listar.value = true;
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        fecharPriorizar();
                        listar.value = true;
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .catch(err => {
                alerta.value = {
                    ...alerta.value,
                    open: true,
                    title: strings.erro,
                    type: 'error',
                    message: strings.erroInvalidarExame,
                    options: [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    }],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                };
            })
            .finally(() => {
                priorizando.value = false;
            });
    }

    return (
        <>
            <SttModal
                title={strings.priorizar}
                open={priorizar}
                outModalClose={fecharPriorizar}
                iconClose={fecharPriorizar}
                maxWidth="sm"
                fullWidth={true}
                children={
                    <div className={classes.modalBody}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12}>
                                <SttAutocomplete
                                    inputprops={{
                                        label: strings.motivo,
                                        required: true
                                    }}
                                    getOptionLabel={option => option.descricao}
                                    options={motivosPriorizacao.value}
                                    value={motivo.value}
                                    onChange={selecionarMotivo}
                                />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <div>
                        <SttButton
                            variant="contained"
                            color="primary"
                            disabled={!motivo.value}
                            onClick={() => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: true,
                                    title: strings.atencao,
                                    type: 'confirmation',
                                    message: strings.confirmarPriorizarExame,
                                    options: [
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                priorizarExame();
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        }
                                    ],
                                    onClose: () => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    }
                                };
                            }}
                        >
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={fecharPriorizar}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
            <div className={classes.carregando}>
                <SttLoading
                    open={priorizando.value}
                    text={strings.priorizandoExame}
                />
            </div>
        </>
    );
}

Priorizar.propTypes = {
    idExame: PropTypes.number.isRequired,
    priorizar: PropTypes.bool.isRequired,
    setPriorizar: PropTypes.func.isRequired,
    idInstituicao: PropTypes.number,
    callback: PropTypes.func
};

export default Priorizar;